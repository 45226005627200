body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tbmargin-10 {
  margin: 10px 0px;
}

.stepper {
  border-top: 4px solid gainsboro;
  color: gainsboro;
  padding-top: 15px;
}

.stepper.stepper-active {
  border-color: #00464d;
  color: #00464d;
}

.card-background {
  background-color: white;
}

.search-result-card {
  margin: 15px 0px;
}

.selected-card {
  background-color: gainsboro;
}

.progress-bar-segment {
  border-radius: 5px;
  height: 10px;
}

.datepickerInput {
  border: 1px solid #d3d4d6;
  border-radius: 8px;
  padding: 8px 1rem;
}
