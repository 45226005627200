.uploadFileField {
  display: none;
}

.drag-drop-zone {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.drag-drop-zone.inside-drag-area {
  opacity: 0.7;
}
